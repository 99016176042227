.title-text {
  font-family: 'Pantasia', serif;
  text-transform: uppercase;
  font-size: 5em;
  padding-top: .5em;
  text-align-last: justify;
  line-height: 0;
  margin-bottom: .6em;
  text-size-adjust: auto;
}

/* @media (min-width: 993px) {
  .title-text {
    font-size: 6em;
    padding-top: 4vw;
  }
} */

@media (min-width: 769px) and (max-width: 992px) {
  .title-text {
    font-size: 6vw;
    padding-top: 4vw;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .title-text {
    font-size: 7vw;
    padding-top: 5vw;
  }
}

@media (max-width: 576px) {
  .title-text {
    padding-top: 7vw;
    font-size: 7vw;
  }
}

.title-text::before {
  content: "";
  display: inline-block;
  width: 100%;
}