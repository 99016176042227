@font-face {
  font-family: 'PPMonumentBold';
  src: url('./fonts/PPMonumentExtended-Bold.woff2') format('woff2'),
       url('./fonts/PPMonumentExtended-Bold.woff') format('woff'),
       url('./fonts/PPMonumentExtended-Bold.ttf') format('truetype');
}

/* @font-face {
  font-family: 'Neue Haas Grotesk';
  src: url('./fonts/NeueHaasGroteskText-Regular.woff') format('woff');
} */

@font-face {
  font-family: 'Pantasia';
  src: url('./fonts/Pantasia-Regular.woff2') format('woff2'),
       url('./fonts/Pantasia-Regular.ttf') format('truetype');
}


/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');

body {
  /* padding-left: 5vw;
  padding-right: 5vw; */
  font-family: "Inter Tight", sans-serif;
  font-size: 1em;
  color: #000000;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.App {
  max-width: 992px;
}

.text-bg-secondary {
  background-color: #b566eb !important;
  color: #ffffff !important;
}

.text-bg-light {
  background-color: #479e58 !important;
  color: #ffffff !important;
}

.text-dark {
  color: #000000 !important;
}

.more {
  font-size: .9em;
  max-height: 0px;
  opacity: 0;
  overflow-y: hidden;
  transition: all .7s;
}
.more.show {
  opacity: 100;
  max-height: 2000px;
}

.pa {
  font-family: 'Pantasia';
}

.mo {
  font-family: 'PPMonumentBold';
}