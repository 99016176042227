.record {
  margin: 0;
  border-top: 1px solid #000000;
  padding-top: .2em;
  padding-bottom: 0em;
}

.notification {
  position: relative;
  top: -2px;
  font-size: .3em;
  padding-top: 2.5px;
}

.record-title {
  font-family: "Pantasia";
  font-size: 1em;
  transition: all .7s;
}

.open-title {
  /* font-weight: 700; */
  /* font-family: "Pantasia"; */
  /* text-transform: uppercase; */
  font-size: 1.8em;
}

.caption {
  font-size: .8em;
}

/* .open-record {
  background-color: rgb(246, 246, 246);
  transition: all .5s;
} */