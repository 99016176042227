.loader {
  margin: auto;
  margin-top: 3em;
  border: 8px solid #ffffff;
  border-radius: 50%;
  border-top: 8px solid #000000;
  width: 50px;
  height: 50px;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}